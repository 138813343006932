import {Routes} from '@angular/router';
import {notAuthenticatedGuard} from './core/guards';
import {deviceGuard} from './core/guards/device.guard';

export const routes: Routes = [
    {
        path: 'sign-in',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/sign-in/sign-in.component').then(c => c.SignInComponent),
    },
    {
        path: 'sign-up',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/sign-up/sign-up.component').then(c => c.SignUpComponent),
    },
    {
        path: 'activate/organization',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/activate/organization/organization-activation.component').then(c => c.OrganizationActivationComponent),
    },
    {
        path: 'activate/user',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/activate/user/user-activation.component').then(c => c.UserActivationComponent),
    },
    {
        path: 'activate/driver',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/activate/driver/driver-activation.component').then(c => c.DriverActivationComponent),
    },
    {
        path: 'forgot-password',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent),
    },
    {
        path: 'create-password',
        canActivate: [notAuthenticatedGuard],
        loadComponent: () => import('./pages/auth/create-password/create-password.component').then(c => c.CreatePasswordComponent),
    },
    {
        path: 'mobile',
        loadChildren: () => import('./pages/portal/mobile/routes').then(r => r.routes),
    },
    {
        path: '',
        canActivate: [deviceGuard],
        loadChildren: () => import('./pages/portal/portal.routes').then(r => r.routes),
    },
] as const;
