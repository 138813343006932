import {Platform} from '@angular/cdk/platform';
import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';

export const deviceGuard: CanActivateFn = () => {
    const platform = inject(Platform);
    const router = inject(Router);
    const mobile = platform.ANDROID || platform.IOS;

    if (mobile) {
        router.navigateByUrl('/mobile/e-cmr');
    }

    return !mobile;
};
