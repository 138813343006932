import {Platform} from '@angular/cdk/platform';
import {ViewportScroller} from '@angular/common';
import {Component, OnInit, Renderer2} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    public constructor(
        router: Router,
        viewportScroller: ViewportScroller,
        private readonly platform: Platform,
        private readonly renderer: Renderer2,
    ) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }

    public ngOnInit(): void {
        if (this.platform.ANDROID || this.platform.IOS) {
            this.renderer.addClass(document.body, 'mobile');
        }
    }
}
