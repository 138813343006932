async function getVersion(): Promise<string> {
    return fetch(`/version?${Date.now()}`, {cache: 'no-store'}).then(res => res.text());
}

getVersion()
    .then(version => {
        setInterval(
            async () => {
                const newVersion = await getVersion();
                if (version != newVersion) {
                    const href = document.location.href;
                    document.location.href = `${href}${href.includes('?') ? '&' : '?'}${Date.now()}`;
                }
            },
            60000,
        );
    });
